import { ENVIRONMENT_SHARED } from 'environment';
import { SubscriptionLocations } from 'ui/components/LocationPicker/LocationNaturalKeyList';
import API from 'utils/api/API';
import { AirlineOption, Gsa } from 'utils/api/AirlinesAPI';
import { FreightForwarderSingleOption } from 'utils/api/FreightForwarderAPI';
import {
	AccountTypeCode,
	Country,
	CustomerTypeOption,
	DeleteSchema,
	Location,
	ReportCountryVariant,
} from 'utils/api/common';
import { GenerateReportModel } from 'utils/schemas/reportSchema';
import {
	CreateSubscriptionModel,
	ProductTypeCode,
	UpdateSubscriptionModel,
} from 'utils/schemas/subscriptionSchema';
import {
	AggregationCode,
	CurrencyCode,
	LabeledValue,
	Option,
	Paginated,
	Separator,
	TimePeriodInterval,
} from 'utils/types/common';
import { YearAndMonth } from 'utils/types/helpers';
import { RenewSubscriptionsModel } from '../routes/customers/UpdateSubscriptionExpiryModal';
import { CustomerStatus } from '../routes/customers/model';
import { CustomerOption } from './CustomerAPI';
import { SystemProcess } from './DataManagementAPI';
import { GetUsageTrackingDataResponse } from './UsageTrackingApi';

export type SubscriptionOption = {
	id: string;
	name: string;
	subscriptionNumber: string;
};

export type SubscriptionDeliveryStatus = {
	name: string;
	value: string;
};

export type SubscriptionStatus = LabeledValue & {
	typeLabel: string;
};

export type UserCount = {
	active: number;
	expiring: number;
	expired: number;
};

export type SubscriptionItem = {
	id: string;
	subscriptionNumber: string;
	name: string;
	customerId: string;
	customerNumber: string;
	customerStatus: CustomerStatus;
	customer: string;
	productName: string;
	origin?: Option<SubscriptionLocations>;
	destination?: Option<SubscriptionLocations>;
	origin2?: Option<SubscriptionLocations>; // Only appropriate for market share
	destination2?: Option<SubscriptionLocations>; // Only appropriate for market share
	origin3?: Option<SubscriptionLocations>; // Only appropriate for market share
	destination3?: Option<SubscriptionLocations>; // Only appropriate for market share
	dataPeriod: string;
	startPeriod: YearAndMonth;
	endPeriod: YearAndMonth;
	status: SubscriptionStatus;
	numberOfLicenses?: Option<number>;
	userCount: UserCount;
};

export type SubscriptionReportItem = {
	id: string;
	jobId: number;
	distributionStatus: string;
	generationStatus: string;
	generationDuration?: number;
	variant?: ReportCountryVariant;
	failureMessage?: string;
	reportDate: YearAndMonth;
	startPeriod: YearAndMonth;
	endPeriod: YearAndMonth;
	createdAt: Date;
	jobStartedAt?: Date;
	jobCompletedAt?: Date;
	filename?: string;
	fileSize?: string;
	fileSizeInBytes?: number;
	suppressDistortion: boolean;
};

export type MonthlyReportItem = {
	id: string;
	subscriptionNumber: string;
	name: string;
	customerId: string;
	customerNumber: string;
	customerStatus: string;
	customer: string;
	productName: string;
	origin?: SubscriptionLocations;
	destination?: SubscriptionLocations;
	origin2?: SubscriptionLocations; // Only appropriate for market share
	destination2?: SubscriptionLocations; // Only appropriate for market share
	origin3?: SubscriptionLocations; // Only appropriate for market share
	destination3?: SubscriptionLocations; // Only appropriate for market share
	dataPeriod: string;
	startPeriod: YearAndMonth;
	endPeriod: YearAndMonth;
	deliveryEnabled: boolean;
	numberOfLicenses: number | undefined;
	userCount: UserCount;
};

export type WeeklyReportItem = {
	id: string;
	subscriptionNumber: string;
	name: string;
	customerId: string;
	customerNumber: string;
	customerStatus: string;
	customer: string;
	productName: string;
	origin?: SubscriptionLocations;
	destination?: SubscriptionLocations;
	startPeriod: YearAndMonth;
	endPeriod: YearAndMonth;
	deliveryEnabled: boolean;
	numberOfLicenses: number | undefined;
	userCount: UserCount;
};

export type AllSubscriptionReportsItem = {
	id: string;
	jobId: number;
	customerName: string;
	customerId: string;
	customerNumber: string;
	subscriptionName: string;
	subscriptionId: string;
	subscriptionNumber: string;
	productName: string;
	distributionStatus: string | null;
	generationStatus: string;
	generationDuration?: number;
	variant: ReportCountryVariant | null;
	origin: SubscriptionLocations | null;
	destination: SubscriptionLocations | null;
	origin2: SubscriptionLocations | null; // Only appropriate for market share
	destination2: SubscriptionLocations | null; // Only appropriate for market share
	origin3: SubscriptionLocations | null; // Only appropriate for market share
	destination3: SubscriptionLocations | null; // Only appropriate for market share
	failureMessage: string | null;
	filename: string | null;
	fileSize: string | null;
	fileSizeInBytes: number | null;
	reportDate: YearAndMonth & { yearMonth: number };
	reportBatchId: string;
	reportBatchName: string;
	createdAt: Date;
	jobStartedAt: Date | null;
	jobCompletedAt: Date | null;
	suppressDistortion: boolean;
};

export type CodeSummary = {
	code: string;
	value: string;
};

export type CddSubmissionModel = {
	jobId: string;
	externalFileId: string;
	externalFile: string;
	subscriptionId: string;
	codeOrPrefix: string;
	contributorName?: string;
	receivedAt: Date;
	status: string;
	statusContributor: string;
	startedAt: Date;
	completedAt: Date;
	rowCount: number | null;
	validRowCount: number | null;
	invalidRowCount: number | null;
	errorCodes: string[];
	errorCodeSummary: CodeSummary[];
	alertCodeSummary: CodeSummary[];
	canDownload: boolean;
};

type GetSubscriptionsRequest = {
	keyword?: string;
	customerIds?: string[];
	show?: string;
	pageSize?: number;
	page?: number;
};

export type GetSubscriptionsResponse = {
	keyword?: Option<string>;
	subscriptionDeliveryStatusCodes: string[];
	subscriptionDeliveryStatusOptions: LabeledValue[];
	customerTypes: string[];
	customerTypeOptions: CustomerTypeOption[];
	customerStatuses: string[];
	customerIds: string[];
	customerStatusOptions: LabeledValue[];
	customersSelected: CustomerOption[];
	productIds: string[];
	productOptions: ProductOption[];
	subscriptions: Paginated<SubscriptionItem>;
	originCountries: string[];
	originCountriesSelected: Country[];
	destinationCountries: string[];
	destinationCountriesSelected: Country[];
	regions: string[];
	regionOptions: LabeledValue[];
	filterOptions: LabeledValue[];
};

type GetAllSubscriptionReportsRequest = {
	keyword?: string;
	customerIds?: string[];
	pageSize?: number;
	page?: number;
};

type GetMonthlyReportsRequest = {
	['ReportDate.Year']: number;
	['ReportDate.Month']: number;
	SubscriptionDeliveryStatus?: string[];
	productTypes?: string[];
	customerStatus?: string[];
	pageSize?: number;
	page?: number;
};

export type GetMonthlyReportsResponse = {
	subscriptionDeliveryStatusOptions: LabeledValue[];
	productOptions: ProductOption[];
	productTypes: string[];
	customersSelected: CustomerOption[];
	customerIds: string[];
	reportDateMonth: number;
	reportDateYear: number;
	customerStatusOptions: LabeledValue[];
	totalEnabledReports: number;
	totalDisabledReports: number;
	totalReports: number;
	totalEnabledSubscriptions: number;
	totalDisabledSubscriptions: number;
	totalSubscriptions: number;
	totalInactiveCustomers: number;
	subscriptions: Paginated<MonthlyReportItem>;
};

type GetWeeklyReportsRequest = {
	yearMonthWeek: number;
	subscriptionDeliveryStatus?: string[];
	productTypes?: string[];
	customerStatus?: string[];
	pageSize?: number;
	page?: number;
};

export type GetWeeklyReportsResponse = {
	subscriptionDeliveryStatusOptions: LabeledValue[];
	productOptions: ProductOption[];
	productTypes: string[];
	customersSelected: CustomerOption[];
	customerIds: string[];
	yearMonthWeekOptions: LabeledValue[];
	yearMonthWeek: LabeledValue;
	customerStatusOptions: LabeledValue[];
	totalEnabledReports: number;
	totalDisabledReports: number;
	totalReports: number;
	totalEnabledSubscriptions: number;
	totalDisabledSubscriptions: number;
	totalSubscriptions: number;
	totalInactiveCustomers: number;
	weeklyReportGenerationProcess: SystemProcess;
	subscriptions: Paginated<WeeklyReportItem>;
};

export type RunMonthlyReportsResponse = {
	message: string;
	reportBatchId: string;
};

export type RunWeeklyReportsResponse = {
	message: string;
	reportBatchId: string;
};

export type RunMonthlyReportsRequest = {
	['ReportDate.Year']: number;
	['ReportDate.Month']: number;
	productTypes?: string[];
	customerIds?: string[];
	runOnNewDataSchedule: boolean;
};

export type RunWeeklyReportsRequest = {
	yearMonthWeek: number;
	productTypes?: string[];
	customerIds?: string[];
	runOnNewDataSchedule: boolean;
};

export type ReRunReportsRequest = {
	id: string[];
};

export type ReRunReportsResponse = {
	message: string;
	reportBatchId: string;
};

export type GetAllSubscriptionReportsResponse = {
	jobId: number | null;
	subscriptionNumber: string | null;
	productTypes: unknown[] | null;
	createdBeforeDate: Date | null;
	createdAfterDate: Date | null;
	startedBeforeDate: Date | null;
	startedAfterDate: Date | null;
	completedBeforeDate: Date | null;
	completedAfterDate: Date | null;
	distributionStatuses: string[] | null;
	generationStatuses: string[] | null;
	productOptions: ProductOption[];
	distributionStatusOptions: LabeledValue[];
	generationStatusOptions: LabeledValue[];
	periodTypeOptions: LabeledValue[];
	periodTypes: string[] | null;
	reportBatchOptions: LabeledValue[];
	reportBatches: string[] | null;
	reports: Paginated<AllSubscriptionReportsItem>;
};

export type CddErrorCode = {
	code: string;
	name: string;
	description: string;
	codeType: string;
};

export type GetSubscriptionReportsResponse = {
	showVariantColumn: boolean;
	showDistributionColumn: boolean;
	reports: Paginated<SubscriptionReportItem>;
};

export type GetCddContributorResponse = {
	submissions: Paginated<CddSubmissionModel>;
	errorCodes: CddErrorCode[];
};

export type ProductOption = {
	id: string;
	name: string;
	productType: ProductTypeCode;
	forAccountType?: AccountTypeCode;
};

export function isProductTypeWebTool(
	product: ProductOption | null | undefined
) {
	return (
		product?.productType === 'web-tool-airline' ||
		product?.productType === 'web-tool-freight-forwarder' ||
		product?.productType === 'web-tool-third-party-operational' ||
		product?.productType === 'web-tool-third-party-revenue' ||
		product?.productType === 'web-tool-iata' ||
		product?.productType === 'capacity-web-tool' ||
		product?.productType === 'capacity-web-tool-iata' ||
		product?.productType === 'ndd-contributor'
	);
}

export function isDeliveryStatusApplicable(
	product: ProductOption | null | undefined
) {
	return (
		product?.productType !== 'web-tool-airline' &&
		product?.productType !== 'web-tool-freight-forwarder' &&
		product?.productType !== 'web-tool-third-party-operational' &&
		product?.productType !== 'web-tool-third-party-revenue' &&
		product?.productType !== 'web-tool-iata' &&
		product?.productType !== 'capacity-web-tool' &&
		product?.productType !== 'capacity-web-tool-iata' &&
		product?.productType !== 'cdd-contributor-airline' &&
		product?.productType !== 'ndd-contributor'
	);
}

export type WebToolHiddenFieldGroup = {
	label: string;
	options: WebToolHiddenFieldOption[];
};

export type WebToolHiddenFieldOption = LabeledValue & {
	group: string;
};

// Every possible property across all report types

export type AllProductDetails = {
	origin: Location[];
	origin2: Location[];
	origin3: Location[];
	originCountries: Country[];
	destination: Location[];
	destination2: Location[];
	destination3: Location[];
	destinationCountries?: Country[];
	currency: LabeledValue<CurrencyCode>;
	aggregation: LabeledValue<AggregationCode>;
	timePeriodInterval: LabeledValue<TimePeriodInterval>;
	weightBreaks?: number[];
	weightBreaksCdd?: number[];
	iiNetAccountId: string;
	includeOtherCharges: boolean;
	yearsOfHistoricalData: number;
	airlines: AirlineOption[];
	groupConsortium: boolean;
	freightForwarders: FreightForwarderSingleOption[];
	isSpecialBipReport: boolean;
	lengthOfRanking: number;
	minimumChargeableCompetitiveWeight: number | undefined;
	scopeType: LabeledValue;
	submissionStatus: LabeledValue;
	gsa: Gsa;
	contributorCode: string;
	contributorName: string;
	dataFileSeparator: LabeledValue<Separator>;
	referenceFileSeparator: LabeledValue<Separator>;

	// Webtool only
	webToolDataSource: LabeledValue;
	webToolDataSubscription: LabeledValue;
	webToolHiddenFields: WebToolHiddenFieldOption[];
	webToolDataGroups: WebToolDataGroupDetails[];
	marketBreakdown: boolean;
};

// BIP

type BipDetailsBase = Pick<
	AllProductDetails,
	| 'originCountries'
	| 'destinationCountries'
	| 'currency'
	| 'weightBreaks'
	| 'iiNetAccountId'
	| 'includeOtherCharges'
	| 'yearsOfHistoricalData'
>;

type BipCddDetailsBase = Pick<
	AllProductDetails,
	| 'originCountries'
	| 'destinationCountries'
	| 'currency'
	| 'weightBreaks'
	| 'weightBreaksCdd'
	| 'iiNetAccountId'
	| 'includeOtherCharges'
	| 'yearsOfHistoricalData'
>;

type CapacityBipDetailsBase = Pick<
	AllProductDetails,
	| 'originCountries'
	| 'destinationCountries'
	| 'iiNetAccountId'
	| 'yearsOfHistoricalData'
>;

export type BipAirlineDetails = BipDetailsBase &
	Pick<AllProductDetails, 'airlines' | 'groupConsortium'> & {
		type: 'bip-airline';
	};

export type BipAirlineWeeklyDetails = BipDetailsBase &
	Pick<AllProductDetails, 'airlines' | 'groupConsortium'> & {
		type: 'bip-airline-weekly';
	};

export type BipAirlineWeeklyPlusDetails = BipDetailsBase &
	Pick<AllProductDetails, 'airlines' | 'groupConsortium'> & {
		type: 'bip-airline-weekly-plus';
	};

export type BipAirlineCddDetails = BipCddDetailsBase &
	Pick<AllProductDetails, 'airlines' | 'groupConsortium'> & {
		type: 'bip-airline-cdd';
	};

export type BipAirlineCddWeeklyDetails = BipCddDetailsBase &
	Pick<AllProductDetails, 'airlines' | 'groupConsortium'> & {
		type: 'bip-airline-cdd-weekly';
	};

export type BipAirlineCddWeeklyPlusDetails = BipCddDetailsBase &
	Pick<AllProductDetails, 'airlines' | 'groupConsortium'> & {
		type: 'bip-airline-cdd-weekly-plus';
	};

export type BipFreightForwarderDetails = BipDetailsBase &
	Pick<AllProductDetails, 'freightForwarders' | 'isSpecialBipReport'> & {
		type: 'bip-freight-forwarder';
	};

export type BipFreightForwarderWeeklyDetails = BipDetailsBase &
	Pick<AllProductDetails, 'freightForwarders' | 'isSpecialBipReport'> & {
		type: 'bip-freight-forwarder-weekly';
	};

export type BipFreightForwarderWeeklyPlusDetails = BipDetailsBase &
	Pick<AllProductDetails, 'freightForwarders' | 'isSpecialBipReport'> & {
		type: 'bip-freight-forwarder-weekly-plus';
	};

export type BipFreightForwarderCddDetails = BipCddDetailsBase &
	Pick<AllProductDetails, 'freightForwarders'> & {
		type: 'bip-freight-forwarder-cdd';
	};

export type BipFreightForwarderCddWeeklyDetails = BipDetailsBase &
	Pick<AllProductDetails, 'freightForwarders' | 'isSpecialBipReport'> & {
		type: 'bip-freight-forwarder-cdd-weekly';
	};

export type BipFreightForwarderCddWeeklyPlusDetails = BipDetailsBase &
	Pick<AllProductDetails, 'freightForwarders' | 'isSpecialBipReport'> & {
		type: 'bip-freight-forwarder-cdd-weekly-plus';
	};

export type BipThirdPartyRevenueDetails = BipDetailsBase & {
	type: 'bip-third-party-revenue';
};

export type BipThirdPartyRevenueWeeklyDetails = BipDetailsBase & {
	type: 'bip-third-party-revenue-weekly';
};

export type BipThirdPartyWeightDetails = BipDetailsBase & {
	type: 'bip-third-party-weight';
};
export type BipThirdPartyWeightWeeklyDetails = BipDetailsBase & {
	type: 'bip-third-party-weight-weekly';
};

export type CapacityBipDetails = CapacityBipDetailsBase & {
	type: 'capacity-bip';
};

// Market Focus Report

type MfDetailsBase = Pick<
	AllProductDetails,
	'originCountries' | 'yearsOfHistoricalData'
>;

type MfCddDetailsBase = Pick<
	AllProductDetails,
	'originCountries' | 'yearsOfHistoricalData'
>;

export type MarketFocusAirlineDetails = MfDetailsBase &
	Pick<AllProductDetails, 'airlines'> & {
		type: 'market-focus-airline';
	};

export type MarketFocusAirlineCddDetails = MfCddDetailsBase &
	Pick<AllProductDetails, 'airlines'> & {
		type: 'market-focus-airline-cdd';
	};

export type MarketFocusFreightForwarderDetails = MfDetailsBase &
	Pick<AllProductDetails, 'freightForwarders'> & {
		type: 'market-focus-freight-forwarder';
	};

export type MarketFocusFreightForwarderCddDetails = MfCddDetailsBase &
	Pick<AllProductDetails, 'freightForwarders'> & {
		type: 'market-focus-freight-forwarder-cdd';
	};

export type MarketFocusThirdPartyDetails = MfDetailsBase & {
	type: 'market-focus-third-party';
};

export type MarketFocusThirdPartyCddDetails = MfCddDetailsBase & {
	type: 'market-focus-third-party-cdd';
};

export type CapacityMarketFocusDetails = MfDetailsBase & {
	type: 'capacity-market-focus';
};

// Top Report

type TopDetailsBase = Pick<
	AllProductDetails,
	'originCountries' | 'yearsOfHistoricalData'
>;

export type TopAirlineDetails = TopDetailsBase &
	Pick<AllProductDetails, 'airlines'> & {
		type: 'top-airline';
	};

export type TopFreightForwarderDetails = TopDetailsBase &
	Pick<AllProductDetails, 'freightForwarders'> & {
		type: 'top-freight-forwarder';
	};

// Standard Report

type StandardReportDetailsBase = Pick<
	AllProductDetails,
	| 'origin'
	| 'destination'
	| 'currency'
	| 'aggregation'
	| 'yearsOfHistoricalData'
>;

export type StandardAirlineDetails = StandardReportDetailsBase &
	Pick<AllProductDetails, 'airlines'> & {
		type: 'standard-airline';
	};

export type StandardFreightForwarderDetails = StandardReportDetailsBase &
	Pick<AllProductDetails, 'freightForwarders'> & {
		type: 'standard-freight-forwarder';
	};

export type StandardThirdPartyDetails = StandardReportDetailsBase & {
	type: 'standard-third-party';
};

// Ranking Report

type RankingDetailsBase = Pick<AllProductDetails, 'originCountries'>;

export type RankingAirlineDetails = RankingDetailsBase &
	Pick<AllProductDetails, 'airlines' | 'yearsOfHistoricalData'> & {
		type: 'ranking-airline';
	};

export type RankingFreightForwarderDetails = RankingDetailsBase &
	Pick<AllProductDetails, 'timePeriodInterval'> & {
		type: 'ranking-freight-forwarder';
	};

// Web Tool

export type WebToolDataGroupDetails = {
	origin: Location[];
	destination: Location[];
	startPeriod: YearAndMonth;
	endPeriod: YearAndMonth;
	includeReverseRoutes: boolean;
};

export type WebToolDetailsBase = Pick<
	AllProductDetails,
	'webToolDataSource' | 'webToolDataSubscription' | 'webToolHiddenFields'
>;

export type CapacityWebToolDetailsBase = Pick<
	AllProductDetails,
	'webToolHiddenFields'
>;

export type WebToolAirlineDetails = WebToolDetailsBase & {
	type: 'web-tool-airline';
	marketBreakdown: boolean;
	webToolDataGroups: (WebToolDataGroupDetails & {
		airlines: AirlineOption[];
	})[];
};

export type WebToolFreightForwarderDetails = WebToolDetailsBase & {
	type: 'web-tool-freight-forwarder';
	marketBreakdown: boolean;
	webToolDataGroups: (WebToolDataGroupDetails & {
		freightForwarders: FreightForwarderSingleOption[];
	})[];
};

export type WebToolThirdPartyOperationalDetails = WebToolDetailsBase & {
	type: 'web-tool-third-party-operational';
	webToolDataGroups: WebToolDataGroupDetails[];
};

export type WebToolThirdPartyRevenueDetails = WebToolDetailsBase & {
	type: 'web-tool-third-party-revenue';
	webToolDataGroups: WebToolDataGroupDetails[];
};

export type WebToolIataDetails = WebToolDetailsBase & {
	type: 'web-tool-iata';
	marketBreakdown: boolean;
};

export type CapacityWebToolDetails = CapacityWebToolDetailsBase & {
	type: 'capacity-web-tool';
	webToolDataGroups: WebToolDataGroupDetails[];
};

export type CapacityWebToolIataDetails = CapacityWebToolDetailsBase & {
	type: 'capacity-web-tool-iata';
	webToolDataGroups: WebToolDataGroupDetails[];
};

export function isWebToolAirlineConfig(details: {
	type: ProductTypeCode;
}): details is WebToolAirlineDetails {
	return details.type === 'web-tool-airline';
}

export function isWebToolFreightForwarderConfig(details: {
	type: ProductTypeCode;
}): details is WebToolFreightForwarderDetails {
	return details.type === 'web-tool-freight-forwarder';
}

// CDD Contributor
type CddContributorDetailsBase = Pick<AllProductDetails, 'submissionStatus'>;

export type CddContributorAirlineDetails = CddContributorDetailsBase &
	Pick<AllProductDetails, 'airlines'> & {
		type: 'cdd-contributor-airline';
	};

// NDD Contributor
type NddContributorDetailsBase = Pick<
	AllProductDetails,
	'submissionStatus' | 'contributorCode' | 'contributorName'
>;

export type NddContributorDetails = NddContributorDetailsBase & {
	type: 'ndd-contributor';
	webToolDataGroups: WebToolDataGroupDetails[];
};

// Discriminated Union of all Products

export type ProductConfigurationDetails =
	| BipAirlineDetails
	| BipAirlineWeeklyDetails
	| BipAirlineWeeklyPlusDetails
	| BipAirlineCddDetails
	| BipAirlineCddWeeklyDetails
	| BipAirlineCddWeeklyPlusDetails
	| BipFreightForwarderDetails
	| BipFreightForwarderWeeklyDetails
	| BipFreightForwarderWeeklyPlusDetails
	| BipFreightForwarderCddDetails
	| BipFreightForwarderCddWeeklyDetails
	| BipFreightForwarderCddWeeklyPlusDetails
	| BipThirdPartyRevenueDetails
	| BipThirdPartyRevenueWeeklyDetails
	| BipThirdPartyWeightDetails
	| BipThirdPartyWeightWeeklyDetails
	| MarketFocusAirlineDetails
	| MarketFocusAirlineCddDetails
	| MarketFocusFreightForwarderDetails
	| MarketFocusFreightForwarderCddDetails
	| MarketFocusThirdPartyDetails
	| MarketFocusThirdPartyCddDetails
	| StandardAirlineDetails
	| StandardFreightForwarderDetails
	| StandardThirdPartyDetails
	| TopAirlineDetails
	| TopFreightForwarderDetails
	| RankingAirlineDetails
	| RankingFreightForwarderDetails
	| WebToolAirlineDetails
	| WebToolFreightForwarderDetails
	| WebToolThirdPartyOperationalDetails
	| WebToolThirdPartyRevenueDetails
	| WebToolIataDetails
	| CddContributorAirlineDetails
	| NddContributorDetails
	| CapacityMarketFocusDetails
	| CapacityBipDetails
	| CapacityWebToolDetails;

export type GetSubscriptionResponse = {
	id: string;
	name: string;
	subscriptionNumber: string;
	deliveryStatus: LabeledValue;
	customer: CustomerOption;
	product: ProductOption;
	startPeriod: YearAndMonth;
	endPeriod: YearAndMonth;
	numberOfLicenses: number | null;
	productConfiguration: ProductConfigurationDetails;
	iataSupportUsers: SubscriptionSupportUserItem[];
	isSupportUser: boolean;
	createdAt: Date;
	updatedAt: Date;
	version: number;
	latestYearMonthForReportGeneration: YearAndMonth;
};

export type WebToolProductTypeOptions = {
	customerMarketBreakdown: boolean;
	dataSourceOptions: LabeledValue[];
	dataSubscriptionOptions: Record<string, LabeledValue[]>;
};

export type GetSubscriptionOptionsResponse = {
	subscriptionDeliveryStatusOptions: LabeledValue[];
	productOptions: ProductOption[];
	customerOptions: CustomerOption[];
	currencyOptions: LabeledValue<CurrencyCode>[];
	aggregationOptions: LabeledValue<AggregationCode>[];
	timePeriodIntervalOptions: LabeledValue<TimePeriodInterval>[];
	marketShareScopeTypeOptions: LabeledValue[];
	submissionStatusOptions: LabeledValue[];
	separatorOptions: LabeledValue<Separator>[];
	webToolOptions: Partial<Record<ProductTypeCode, WebToolProductTypeOptions>>;
	webToolHiddenFields: WebToolHiddenFieldGroup[];
	capacityWebToolHiddenFields: WebToolHiddenFieldGroup[];
};

export type SubscriptionSupportUserItem = {
	id: string;
	name: string;
	createdOn: Date;
};

export type SubscriptionSupportUserResponse = {
	users: SubscriptionSupportUserItem[];
};

export type CreateSubscriptionResponse = {
	subscriptionId: string;
};

export type GenerateReportResponse = {
	staticReportId: string;
};

export type AvailableUserItem = {
	id: string;
	customer: {
		id: string;
		name: string;
		customerNumber: string;
	};
	firstName: string;
	lastName: string;
	email: string;
	activeFrom: Date;
	activeTo: Date;
	isSubscribed: boolean;
	subscriptionCount: {
		active: number;
		expiring: number;
		expired: number;
	};
};

export type GetAvailableSubscriptionUsersResponse = {
	keyword: string | null;
	manageUsers: true;
	users: Paginated<AvailableUserItem>;
};

export type GetSubscriptionDownloadResponse = {
	url: string;
	fileName: string;
};

export type SubscriptionLookupResponse = {
	keyword: string;
	options: SubscriptionOption[];
};

type AddSubscriptionSupportUserPayload = {
	auditComment: string;
};

type DeleteSubscriptionSupportUserPayload = {
	auditComment: string;
};

export type RenewSubscriptionsResponse = {
	renewedSubscriptions: Array<RenewedSubscription>;
	extendedUsers: Array<ExtendedUser>;
};

type RenewedSubscription = {
	id: string;
	name: string;
	number: string;
	oldEndPeriod: YearAndMonth;
	newEndPeriod: YearAndMonth;
	webToolGroups?: Array<RenewedWebToolGroup>;
};

type RenewedWebToolGroup = {
	oldEndPeriod: YearAndMonth;
	newEndPeriod: YearAndMonth;
};

type ExtendedUser = {
	id: string;
	name: string;
	newActiveTo: Date;
	oldActiveTo: Date;
};

class SubscriptionAPI extends API {
	protected static readonly apiEndpoint =
		ENVIRONMENT_SHARED.subscriptionsApiEndpoint;

	static lookup({
		keyword,
		productTypes,
	}: {
		keyword: string;
		productTypes?: string[];
	}) {
		const params = new URLSearchParams();
		for (const type of productTypes ?? []) params.append('productTypes', type);
		params.set('keyword', keyword);

		const queryString = `/subscriptions/lookup?${params}`;

		return SubscriptionAPI.get<SubscriptionLookupResponse>(queryString);
	}

	static getSubscriptions(request: GetSubscriptionsRequest | URLSearchParams) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as Record<string, string>);

		return SubscriptionAPI.get<GetSubscriptionsResponse>(
			`/subscriptions?${params}`
		);
	}

	static getSubscriptionsDownloadData(request: URLSearchParams) {
		return SubscriptionAPI.get<GetSubscriptionDownloadResponse>(
			`/subscriptions/download?${request}`
		);
	}

	static getSubscriptionOptions(customerId?: string | null) {
		return SubscriptionAPI.get<GetSubscriptionOptionsResponse>(
			`/subscriptions/options?customerId=${customerId}`
		);
	}

	static addSubscriptionSupportUser(
		subscriptionId: string,
		data: AddSubscriptionSupportUserPayload
	) {
		return SubscriptionAPI.post<SubscriptionSupportUserResponse>(
			`/subscriptions/${subscriptionId}/support-user`,
			data
		);
	}

	static deleteSubscriptionSupportUser(
		subscriptionId: string,
		data: DeleteSubscriptionSupportUserPayload
	) {
		return SubscriptionAPI.delete<SubscriptionSupportUserResponse>(
			`/subscriptions/${subscriptionId}/support-user`,
			data
		);
	}

	static createSubscription(data: CreateSubscriptionModel) {
		return SubscriptionAPI.post<CreateSubscriptionResponse>(
			'/subscriptions',
			data
		);
	}

	static getSubscription(id: string) {
		return SubscriptionAPI.get<GetSubscriptionResponse>(`/subscriptions/${id}`);
	}

	static getSubscriptionDownloadData(customerId: string) {
		return SubscriptionAPI.get<GetSubscriptionDownloadResponse>(
			`/subscriptions/${customerId}/download`
		);
	}

	static updateSubscription(id: string, data: UpdateSubscriptionModel) {
		return SubscriptionAPI.put(`/subscriptions/${id}`, data);
	}

	static renewSubscriptions(data: RenewSubscriptionsModel) {
		return SubscriptionAPI.put<RenewSubscriptionsResponse>(
			`/subscriptions/renew`,
			data
		);
	}

	static deleteSubscription(id: string, data: DeleteSchema) {
		return SubscriptionAPI.delete(`/subscriptions/${id}`, data);
	}

	static generateReport(id: string, data: GenerateReportModel) {
		return SubscriptionAPI.post<GenerateReportResponse>(
			`/subscriptions/${id}/run-report`,
			data
		);
	}

	static getAllSubscriptionReports(
		request: GetAllSubscriptionReportsRequest | URLSearchParams
	) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as Record<string, string>);

		return SubscriptionAPI.get<GetAllSubscriptionReportsResponse>(
			`/subscriptions/reports?${params}`
		);
	}

	static getWeeklyReports(request: GetWeeklyReportsRequest | URLSearchParams) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as unknown as Record<string, string>);

		return SubscriptionAPI.get<GetWeeklyReportsResponse>(
			`/subscriptions/weekly-reports?${params}`
		);
	}

	static getMonthlyReports(
		request: GetMonthlyReportsRequest | URLSearchParams
	) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as unknown as Record<string, string>);

		return SubscriptionAPI.get<GetMonthlyReportsResponse>(
			`/subscriptions/monthly-reports?${params}`
		);
	}

	static runMonthlyReports(
		request: RunMonthlyReportsRequest | URLSearchParams
	) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as unknown as Record<string, string>);

		return SubscriptionAPI.get<RunMonthlyReportsResponse>(
			`/subscriptions/run-monthly-reports?${params}`
		);
	}

	static runWeeklyReports(request: RunWeeklyReportsRequest | URLSearchParams) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as unknown as Record<string, string>);

		return SubscriptionAPI.get<RunWeeklyReportsResponse>(
			`/subscriptions/run-weekly-reports?${params}`
		);
	}

	static reRunReports(request: ReRunReportsRequest | URLSearchParams) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as unknown as Record<string, string>);

		return SubscriptionAPI.get<ReRunReportsResponse>(
			`/subscriptions/reports/re-run?${params}`
		);
	}

	static getSubscriptionReports(id: string, request: URLSearchParams) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as Record<string, string>);

		return SubscriptionAPI.get<GetSubscriptionReportsResponse>(
			`/subscriptions/${id}/reports?${params}`
		);
	}

	static downloadReport(id: string) {
		return SubscriptionAPI.get<{ filename: string; url: string }>(
			`/subscriptions/reports/${id}/download`
		);
	}

	static getUsersForSubscription(id: string) {
		return SubscriptionAPI.get<GetAvailableSubscriptionUsersResponse>(
			`/subscriptions/${id}/users`
		);
	}

	static getAvailableUsersForSubscription(
		id: string,
		searchParams?: URLSearchParams
	) {
		const searchParamsString =
			searchParams && searchParams.toString().length > 1
				? `&${searchParams}`
				: '';
		return SubscriptionAPI.get<GetAvailableSubscriptionUsersResponse>(
			`/subscriptions/${id}/users?manageUsers=true${searchParamsString}`
		);
	}

	static updateUsersForSubscription(
		id: string,
		userIds: string[],
		auditComment: string
	) {
		return SubscriptionAPI.put(`/subscriptions/${id}/users`, {
			userIds,
			auditComment,
		});
	}

	static toggleDeliveryStatus(id: string) {
		return SubscriptionAPI.put(`/subscriptions/toggle-delivery-status`, { id });
	}

	static beginReportsDataExport(request: URLSearchParams) {
		return SubscriptionAPI.post<GetUsageTrackingDataResponse>(
			`/subscriptions/reports/export?${request}`
		);
	}

	static getCddContributorList(id: string, request: URLSearchParams) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as Record<string, string>);
		return SubscriptionAPI.get<GetCddContributorResponse>(
			`/subscriptions/${id}/cdd-submissions?${params}`
		);
	}

	static getNddContributorList(id: string, request: URLSearchParams) {
		const params =
			request instanceof URLSearchParams
				? request
				: new URLSearchParams(request as Record<string, string>);
		return SubscriptionAPI.get<GetCddContributorResponse>(
			`/subscriptions/${id}/ndd-submissions?${params}`
		);
	}
}

export default SubscriptionAPI;
